<template>
  <div class="app_con">
    <app-list
      :rules="rules"
      :remote="remote"
      :props="props"
      :formProps="formProps"
      :searchQuery="searchQuery"
      :apiName="apiName"
      :editTitle="'市场管理制度'"
    >
      <div class="search" slot="tool">
        <el-input
          placeholder="请输入标题"
          v-model="searchQuery.title"
          class="searchInput"
        >
          <template slot="prepend">标题</template>
        </el-input>
      </div>
    </app-list>
  </div>
</template>

<script>
import api from "@/api";

export default {
  data() {
    return {
      rules:{
        //表单验证
         title: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error("标题不能为空"));
              } else {
                callback();
              }
            },
            required: true,
            trigger: ["blur", "change"],
          },
        ],
         executionDate: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error("日期不能为空"));
              } else {
                callback();
              }
            },
            required: true,
            trigger: ["blur", "change"],
          },
        ],
         content: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error("内容不能为空"));
              } else {
                callback();
              }
            },
            required: true,
            trigger: ["blur", "change"],
          },
        ],
      },
      remote: api.common,
      apiName: "/market/system",
      searchQuery: { title: "" },
      props: [
        {
          label: "标题",
          prop: "title",
          width: 1000,
        },

        {
          label: "执行时间",
          prop: "executionDate",
          align: "center",
        },
        {
          label: "状态",
          prop: "status",
          align: "center",
          formatter: function (row) {
            const statusMap = { 0: "启用", 1: "禁用" };
            return statusMap[row.status];
          },
        },

        {
          label: "创建时间",
          prop: "createTime",
          align: "center",
        },
      ],
      formProps: [
        {
          label: "标题",
          type: "input",
          prop: "title",
        },

        {
          label: "执行时间",
          type: "date",
          prop: "executionDate",
        },
        {
          label: "内容",
          type: "editor",
          isFull: true,
          prop: "content",
        },
      ],
    };
  },
  methods: {
    // resetSearch() {
    //   this.searchQuery.title = this.title;
    // },
  },
};
</script>

<style lang="scss" scoped>
</style>
